import styled from "styled-components"
import { ButtonLink } from "components/Buttons"
import { SmallHeader, Paragraph } from "components/Typography"

export const ArrowWrapper = styled.div`
  cursor: pointer;
  margin-top: 15px;
  svg {
    path,
    circle {
      transition: 0.2s all ease;
    }
    &:hover {
      path {
        stroke: #abc539;
      }
      circle {
        stroke: #abc539;
      }
    }
  }
  @media screen and (max-width: 861px) {
    position: absolute;
    top: 0;
    left: 50%;
    &:nth-child(1) {
      transform: translateX(calc(-50% - 40px));
    }
    &:nth-child(3) {
      transform: translateX(calc(-50% + 40px));
    }
  }
`

export const CasestudyContainer = styled.div`
  width: 100%;
  overflow: hidden;
`

export const CasestudyWrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 861px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const CasestudySliderWrapper = styled.div`
  margin-top: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 27px;
    left: 48%;
    width: 100vw;
    height: 120%;
    background-color: #f8f8f8;
    z-index: -1;
    @media screen and (max-width: 861px) {
      display: none;
    }
  }
  .swiper-container {
    padding-bottom: 27px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    width: calc(100% - 120px);
  }
  @media screen and (max-width: 861px) {
    margin-top: 40px;
    padding-top: 100px;
    width: 100%;
    margin: 0;
  }
`

export const SlideImageBox = styled.div`
  width: 50%;
  height: 400px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    width: 35%;
  }
  @media screen and (max-width: 861px) {
    max-width: 400px;
    height: auto;
  }
`

export const SlideTextBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
  ${ButtonLink} {
    transform: translateY(27px);
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    width: 65%;
    padding-left: 120px;
  }
  @media screen and (max-width: 861px) {
    width: 100%;
    background-color: #f8f8f8;
    padding: 40px 20px 0 20px;
    margin-top: 40px;
  }
`

export const SlideImageWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    padding-left: 20px;
  }
  @media screen and (max-width: 861px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 0px;
  }
`
export const SlidesWrapper = styled.div`
  max-width: 960px;
  width: 100%;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    max-width: 860px;
  }
`

export const TitleWrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    max-width: 860px;
  }
`

export const SlideTextWrapper = styled.div`
  /* max-width: 270px; */
  width: 100%;
  flex: 1;
  ${SmallHeader} {
    margin-bottom: 30px;
  }
  ${Paragraph} {
    font-weight: 300;
    font-size: 16px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    max-width: 300px;
    ${SmallHeader} {
      font-size: 20px;
    }
    ${Paragraph} {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 861px) {
    max-width: 100%;
  }
`

export const PaginationWrapper = styled.div`
  & > div {
    width: auto;
    font-size: 26px;
    color: ${props => props.theme.color.gray};
    .swiper-pagination-current {
      color: ${props => props.theme.color.black};
    }
  }
`
