import React from "react"
import {
  CardWrapper,
  TitleContainer,
  DescriptionContainer,
  LinkContainer,
  ImageContainer,
} from "./styles"
import { ArrowLong } from "components/Icons"
import { Link } from "gatsby"
import { withTheme } from "styled-components"
import { SmallHeader, Paragraph } from "components/Typography"

const Card = withTheme(({ img, title, description, url, theme }) => {
  return (
    <CardWrapper to={url}>
      <ImageContainer>{img}</ImageContainer>
      <TitleContainer>
        <SmallHeader color={theme.color.green}>{title}</SmallHeader>
      </TitleContainer>
      <DescriptionContainer>
        <Paragraph>{description}</Paragraph>
      </DescriptionContainer>
      <LinkContainer>
        <Link style={{ padding: "20px" }}>
          <ArrowLong width={"27px"} color={theme.color.green} />
        </Link>
      </LinkContainer>
    </CardWrapper>
  )
})

export { Card }
