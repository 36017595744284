import styled from "styled-components"

export const PartnersWrapper = styled.div`
  margin: 150px auto;
  max-width: 1250px;
  width: 100%;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    max-width: 860px;
  }
  @media screen and (max-width: 861px) {
    padding-left: 20px;
    padding-right: 20px;
    margin: 60px 0;
  }
`

export const PartnersSlider = styled.div`
  margin-top: 180px;
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
  @media screen and (max-width: 861px) {
    margin-top: 60px;
  }
`

export const SlideImageWrapper = styled.div`
  width: 100%;
  height: 120px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const SlideImageBox = styled.div`
  filter: grayscale(50);
  opacity: 0.6;
  transition: 0.4s all ease;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  &:hover {
    filter: grayscale(0);
    cursor: pointer;
    opacity: 1;
  }
`
