import styled from "styled-components"

import { NormalHeader, Paragraph } from "components/Typography"
import {
  IconWrapper,
  IconPath,
  IconCircle,
  IconPolygon,
  IconRect,
  IconPathStroke,
  IconCircleStroke,
  IconPolygonStroke,
  IconRectStroke,
} from "shared/Styles/Icons"

export const ArrowWrapper = styled.div`
  width: 27px;
`

export const IconWrap = styled.div`
  width: 94px;
  transition: 0.4s all ease;
`

export const Text = styled(Paragraph)`
  width: 290px;
  line-height: 1.9;
  color: ${props => props.theme.color.gray};
  font-weight: 300;
  transition: 0.4s all ease;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    width: 270px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    font-size: 14px;
  }
`

export const Title = styled(NormalHeader)`
  width: 330px;
  font-weight: 300;
  color: ${props => props.theme.color.gray};
  transition: 0.4s all ease;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    font-size: 24px;
    width: 260px;
  }
`
export const CompantyAssetsCardContainer = styled.div`
  padding: 45px 100px 45px 75px;
  border-left: 10px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.4s all ease;
  border-bottom: 1px solid #dbdbdb;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0);
  background-color: transparent;
  position: relative;
  svg {
    path {
      transition: 0.4s all ease;
    }
  }
  &:hover {
    border-left: 10px solid ${props => props.theme.color.green};
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid transparent;
    background-color: #fff;
    z-index: 2;
    ${Title},
    ${Text} {
      color: ${props => props.theme.color.green};
    }
    ${ArrowWrapper} {
      svg {
        path {
          stroke: ${props => props.theme.color.green};
        }
      }
    }
    ${IconWrap} {
      ${IconWrapper} {
        ${IconPathStroke},
        ${IconCircleStroke},
        ${IconPolygonStroke},
        ${IconRectStroke} {
            stroke: ${props => props.theme.color.green};
        }
        ${IconPath},
        ${IconCircle},
        ${IconPolygon},
        ${IconRect} {
            fill: ${props => props.theme.color.green};
        }
      }
    }
  }
  @media screen and ( max-width: ${props =>
    props.theme.breakpoints.laptopSmall}) {
        padding: 45px 20px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      padding: 35px 10px;
      ${Title},
      ${Text} {
        width: 100%;
        margin: 5px 0;
    }
  }
`
