import styled from "styled-components"

export const MainSliderContainer = styled.div`
  max-width: 100vw;
  width: 100%;
  height: calc(100vh - 130px);
  position: relative;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    height: calc(100vh - 100px);
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    height: calc(100vh - 70px);
  }
`
