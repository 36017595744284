import React from "react"
import VideoSrc from "assets/video/slidervid.mp4"
import Poster from "assets/img/Index/poster.png"
import { useStaticQuery, graphql } from "gatsby"
import { VideoContainer } from "./styles"
import Image from "gatsby-image"
const Video = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "video/bg.jpg" }) {
        childImageSharp {
          fixed(width: 1920, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <VideoContainer>
      <video src={VideoSrc} autoPlay loop muted poster={Poster} />
      <Image fixed={data.bg.childImageSharp.fixed} />
    </VideoContainer>
  )
}

export { Video }
