import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const Service = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 97 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPathStroke
      color={color}
      d="M1.06445 60.2654H23.4951"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M77.04 60.2654H91.5114"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M27.9473 14.8768V60.2577H55.8743V14.8768C55.8743 10.7513 54.0125 1 41.9108 1C29.8091 1 27.9473 10.7513 27.9473 14.8768Z"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M55.1177 17.5022H28.7002"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M55.1177 28.0046H28.7002"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M55.1177 49.7559H28.7002"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M55.1177 38.5049H28.7002"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M62.2705 9.3291V59.8055H73.8403V9.3291H62.2705Z"
      strokeWidth="3"
    />
    <IconPathStroke
      color={color}
      d="M73.5303 32.4595H62.5859"
      strokeWidth="3"
    />
    <IconPathStroke
      color={color}
      d="M73.5303 17.9951H62.5859"
      strokeWidth="3"
    />
    <IconPathStroke
      color={color}
      d="M73.5303 39.7896H62.5859"
      strokeWidth="3"
    />
    <IconPathStroke
      color={color}
      d="M73.5303 25.3254H62.5859"
      strokeWidth="3"
    />
    <IconPathStroke color={color} d="M73.5303 53.52H62.5859" strokeWidth="2" />
    <IconPathStroke
      color={color}
      d="M73.5303 46.5981H62.5859"
      strokeWidth="3"
    />
    <IconPathStroke
      color={color}
      d="M59.6758 60.2654H77.765"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M1.06734 29.1515L22.7744 29.1515M3.23804 22.6394L20.7846 22.6394L20.7846 29.1515L3.23804 29.1515L3.23804 22.6394Z"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M1.06734 29.1515L22.7744 29.1515M3.23804 22.6394L20.7846 22.6394L20.7846 29.1515L3.23804 29.1515L3.23804 22.6394Z"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M1.06734 40.7279L22.7744 40.7279M3.23804 34.2158L20.7846 34.2158L20.7846 40.7279L3.23804 40.7279L3.23804 34.2158Z"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M1.06734 52.3066L22.7744 52.3066M3.23804 46.5181L20.7846 46.5181L20.7846 52.3066L3.23804 52.3066L3.23804 46.5181Z"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M11.9189 59.5408V53.7522"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M11.9189 45.0695V41.4517"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M11.9189 33.4929V30.5986"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconPathStroke
      color={color}
      d="M76.7315 60.2648C86.8916 60.2648 95.128 52.004 95.128 41.8138C95.128 31.6236 86.8916 23.3628 76.7315 23.3628H74.1445"
      strokeWidth="3"
    />
  </IconWrapper>
)

export { Service }
