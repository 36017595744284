import styled from "styled-components"

export const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  video {
    object-position: center;
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.color.green};
    z-index: 2;
    background: rgba(154, 192, 0, 0.7);
  }
  @media screen and (max-device-aspect-ratio: 4/3) {
    video {
      height: 100%;
      width: auto;
      object-fit: cover;
    }
  }
`
