import styled from 'styled-components';

export const LabelContent = styled.div`
    max-width: 1920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

export const LabelWrapper = styled.div`
    width: 100%;
    height: unset;
    display: flex;
    flex-direction: column;

    margin-bottom: 170px;
    background: ${props => props.theme.color.green};
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.tabletSmall}) {
        height: 170px;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 580px;
    }
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        width: 750px;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.laptopBig}) {
        width: 969px;
    }
`;

export const TitleWrapper = styled.div`
    padding-left: 20px;
    height: 124px;
    display: flex;
    align-items: center;
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        padding-left: 100px;
        height: unset;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.laptopBig}) {
        padding-left: 200px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 124px;
    text-align: center;
    position: relative;
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.tabletSmall}) {
        height: 100%;
        width: 280px;
    }
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.tabletBig}) {
        width: 200px;
    }
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        width: 360px;
    }
`;

export const Button = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${props => props.theme.color.white};
    font-size: 22px;
    font-weight: 700;
    background: ${props => props.theme.color.green};
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.tabletSmall}) {
        width: 140px;
    }
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.tabletBig}) {
        width: 100px;
    }
    @media screen and (min-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        width: 180px;
    }

    &:hover {
        background: ${props => props.theme.color.lightGreen};
    }
`;
