import React from 'react';

import {SectionTitle} from 'components/Typography';
import {
  Button,
  AboutUsContainer,
  AboutUsImageWrapper,
  AboutUsWrapper,
  AboutUsTextWrapper,
  Text,
  Title,
} from './styles';
import {BrushedTextContainer} from 'components/BrushedTextContainer';
import Image from 'gatsby-image';
import {useStaticQuery, graphql} from 'gatsby';

const AboutUs = ({data}) => {
  const image = useStaticQuery(graphql`
      query {
          image: file(relativePath: { eq: "img/Index/about_us.png" }) {
              childImageSharp {
                  fluid(maxWidth: 680, maxHeight: 471, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `);
  return (
    <AboutUsContainer>
      <BrushedTextContainer x={[
        -10,
        15,
      ]}>
        {data.parallaxText}
      </BrushedTextContainer>
      <AboutUsWrapper>
        <SectionTitle>{data.section_title}</SectionTitle>
        <AboutUsTextWrapper>
          <Title>{data.title}</Title>
          <Text>{data.text}</Text>
          <Button to={data.button.href}>
            {data.button.text}
          </Button>
        </AboutUsTextWrapper>
        <AboutUsImageWrapper>
          <Image fluid={image.image.childImageSharp.fluid}/>
        </AboutUsImageWrapper>
      </AboutUsWrapper>
    </AboutUsContainer>
  );
};

export {AboutUs};
