import { useStaticQuery, graphql } from "gatsby"

export const useProjectsImage = () => {
  const data = useStaticQuery(graphql`
    query {
      vox: file(relativePath: { eq: "img/Index/pos-vox.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lech: file(relativePath: { eq: "img/Index/pos-lech.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      adamed: file(relativePath: { eq: "img/Index/pos-adamed.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cersanit: file(relativePath: { eq: "img/Index/pos-cersanit.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      tropidog: file(relativePath: { eq: "img/Index/pos-tropidog.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      prymat: file(relativePath: { eq: "img/Index/pos-prymat.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      jordan: file(relativePath: { eq: "img/Index/pos-jordan.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      safety: file(relativePath: { eq: "img/Index/pos-safety.jpg" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      shop: file(relativePath: { eq: "img/Index/pos-shop-in-shop.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      stojaki: file(relativePath: { eq: "img/Index/pos-stojaki.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      inne: file(relativePath: { eq: "img/Index/pos-inne.jpg" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cheetos: file(relativePath: { eq: "img/Index/pos-cheetos.jpg" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      farm: file(relativePath: { eq: "img/Index/pos-farm.jpg" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pod: file(relativePath: { eq: "img/Index/pos-pod.jpg" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cer: file(relativePath: { eq: "img/Index/pos-cer.png" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      b_inne: file(relativePath: { eq: "img/Index/pos-b-inne.jpg" }) {
        childImageSharp {
          fixed(height: 190, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return data
}
