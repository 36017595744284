import styled, { css } from "styled-components"

const ButtonStyles = css`
  font-size: 14px;
  background-color: ${props => props.theme.color.green};
  padding: 18px 32px;
  color: #fff;
  border: none;
  border: 2px solid ${props => props.theme.color.green};
  transition: 0.4s all ease;
  cursor: pointer;
  display: inline-block;
  &:hover {
    background: #fff;
    color: ${props => props.theme.color.green};
  }
`

const Button = styled.button`
  ${ButtonStyles}
`

export { Button, ButtonStyles }
