import { Link } from "gatsby"
import styled from "styled-components"

import { ButtonStyles } from "./Button"

const ButtonLink = styled(Link)`
  ${ButtonStyles};
  display: inline-block;
  text-decoration: none;
`

export { ButtonLink }
