import {useStaticQuery, graphql} from 'gatsby';

export const usePartnersImage = () => {
  return useStaticQuery(graphql`
        query {
            image_1: file(relativePath: { eq: "img/logos/01.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_2: file(relativePath: { eq: "img/logos/02.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_3: file(relativePath: { eq: "img/logos/03.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_4: file(relativePath: { eq: "img/logos/04.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_5: file(relativePath: { eq: "img/logos/05.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_6: file(relativePath: { eq: "img/logos/06.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_7: file(relativePath: { eq: "img/logos/07.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_8: file(relativePath: { eq: "img/logos/08.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_9: file(relativePath: { eq: "img/logos/09.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_10: file(relativePath: { eq: "img/logos/10.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_11: file(relativePath: { eq: "img/logos/11.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_12: file(relativePath: { eq: "img/logos/12.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_13: file(relativePath: { eq: "img/logos/13.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_14: file(relativePath: { eq: "img/logos/14.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_15: file(relativePath: { eq: "img/logos/15.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_16: file(relativePath: { eq: "img/logos/16.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_17: file(relativePath: { eq: "img/logos/17.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_18: file(relativePath: { eq: "img/logos/18.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_19: file(relativePath: { eq: "img/logos/19.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_20: file(relativePath: { eq: "img/logos/20.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_21: file(relativePath: { eq: "img/logos/21.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_22: file(relativePath: { eq: "img/logos/22.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_23: file(relativePath: { eq: "img/logos/23.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_24: file(relativePath: { eq: "img/logos/24.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_25: file(relativePath: { eq: "img/logos/25.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_26: file(relativePath: { eq: "img/logos/26.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_27: file(relativePath: { eq: "img/logos/27.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_28: file(relativePath: { eq: "img/logos/28.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_29: file(relativePath: { eq: "img/logos/29.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_30: file(relativePath: { eq: "img/logos/30.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_31: file(relativePath: { eq: "img/logos/31.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_32: file(relativePath: { eq: "img/logos/32.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_33: file(relativePath: { eq: "img/logos/33.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_34: file(relativePath: { eq: "img/logos/34.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_35: file(relativePath: { eq: "img/logos/15.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            image_36: file(relativePath: { eq: "img/logos/36.png" }) {
                childImageSharp {
                    fluid(maxWidth: 160, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
  `)
}
