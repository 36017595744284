import React from "react"

import { ArrowLong } from "components/Icons/"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import {
  ArrowWrapper,
  CompantyAssetsCardContainer,
  IconWrap,
  Text,
  Title,
} from "./styles"

const CompantyAssetsCard = ({ props }) => {
  return (
    <AnchorLink to={props.href}>
      <CompantyAssetsCardContainer>
        <IconWrap>{props.icon}</IconWrap>
        <Title>{props.title}</Title>
        <Text>{props.text}</Text>
        <ArrowWrapper>
          <ArrowLong width={"27px"} height={"8px"} color={"#D7D7D7"} />
        </ArrowWrapper>
      </CompantyAssetsCardContainer>
    </AnchorLink>
  )
}
export { CompantyAssetsCard }
