import React from 'react';

import {CompantyAssetsCard} from './CompanyAssetsCard';
import {CompanyAssetsContainer, CompantyAssetsWrapper} from './styles';
import {SectionTitle} from 'components/Typography';
import {BrushedTextContainer} from 'components/BrushedTextContainer';

const CompanyAssets = ({data}) => {
  return (
    <CompanyAssetsContainer>
      <BrushedTextContainer x={[
        '0',
        '50',
      ]}>
        {data.parallaxText}
      </BrushedTextContainer>
      <SectionTitle>{data.title}</SectionTitle>
      <CompantyAssetsWrapper>
        {data.data.map((item, i) => (
          <CompantyAssetsCard props={item} key={i}/>
        ))}
      </CompantyAssetsWrapper>
    </CompanyAssetsContainer>
  );
};

export {CompanyAssets};
