import React from 'react';
import SEO from 'components/seo';

import {CardSlider} from 'sections/Homepage/Projects/CardSlider';

import {MainSlider} from 'sections/Homepage/MainSlider';
import {AboutUs} from 'sections/Homepage/AboutUs';
import {Partners} from 'sections/Homepage/Partners';
import {CasestudySlider} from 'sections/Homepage/Casestudy/CasestudySlider';
import {CompanyAssets} from 'sections/Homepage/CompanyAssets';
import {ParallelogramBG} from 'components/ParallaxShapes';

const Homepage = ({data}) => {
  return (
    <>
      <div style={{position: 'relative'}}>
        <ParallelogramBG
          y={[
            '-100px',
            '600px',
          ]}
          left={'100px'}
          width={'200px'}
        />
        <ParallelogramBG
          wide
          y={[
            '-300px',
            '200px',
          ]}
          left={'350px'}
          width={'150px'}
        />
        <ParallelogramBG
          y={[
            '-300px',
            '800px',
          ]}
          right={'150px'}
          width={'140px'}
        />
      </div>
      <MainSlider data={data.slider}/>
      <SEO title={data.title}/>
      <AboutUs data={data.aboutUs}/>
      <div style={{position: 'relative'}}>
        <CardSlider data={data.cardsSlider}/>
      </div>
      <CompanyAssets data={data.companyAssets}/>
      <CasestudySlider data={data.caseStudy}/>
      <Partners data={data.partners}/>
    </>
  );
};

export default Homepage;
