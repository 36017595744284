import {useStaticQuery, graphql} from 'gatsby';

export const useCaseStudyImage = () => {
  return useStaticQuery(graphql`
      query {
          image_1: file(
              relativePath: { eq: "img/Index/case_slider/cersanit.png" }
          ) {
              childImageSharp {
                  fluid(maxWidth: 380, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          image_2: file(relativePath: { eq: "img/Index/case_slider/futuro.png" }) {
              childImageSharp {
                  fluid(maxWidth: 380, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          image_3: file(relativePath: { eq: "img/Index/case_slider/deli2go.png" }) {
              childImageSharp {
                  fluid(maxWidth: 380, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          image_4: file(relativePath: { eq: "img/Index/case_slider/vox.png" }) {
              childImageSharp {
                  fluid(maxWidth: 380, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `);
};
