import React, {useState} from 'react';
import {SwiperSlide} from 'swiper/react';
import {Card} from 'sections/Homepage/Projects/Card';
import {ArrowRightCircle, ArrowLeftCircle} from 'components/Icons';
import {withTheme} from 'styled-components';

import {
  Container,
  StyledSwiper,
  ArrowLeftWrapper,
  ArrowRightWrapper,
} from './styles';

import {SLIDER_OPTIONS} from './consts';
import {useBackgroundImage} from 'shared/Hooks/Background';
import Image from 'gatsby-image';
import {BrushedTextContainer} from 'components/BrushedTextContainer';

import {Label} from '../Label';

const CardSlider = withTheme(({
  theme,
  data,
}) => {
  const [sliderData, setSliderData] = useState(data.projects[1]);
  const imgs = useBackgroundImage();

  const handleClickIndustry = () => {
    setSliderData(data.projects[0]);
  };
  const handleClickType = () => {
    setSliderData(data.projects[1]);
  };

  return (
    <Container>
      <Label
        data={data.label}
        handleClickIndustry={handleClickIndustry}
        handleClickType={handleClickType}
      />
      <BrushedTextContainer posY={'-190px'} x={[
        '75',
        '30',
      ]}>
        {data.parallaxText}
      </BrushedTextContainer>
      <ArrowRightWrapper className="sliderNext">
        <ArrowRightCircle width={'60px'} color={theme.color.green}/>
      </ArrowRightWrapper>
      <ArrowLeftWrapper className="sliderPrev">
        <ArrowLeftCircle width={'60px'} color={theme.color.green}/>
      </ArrowLeftWrapper>
      <StyledSwiper {...SLIDER_OPTIONS}>
        {sliderData.map((project, index) => (
          <SwiperSlide key={index}>
            <Card
              title={project.title}
              description={project.description}
              url={project.url}
              img={project.img}
            />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Container>
  );
});

export {CardSlider};
