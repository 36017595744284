import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const Solutions = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 95 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPathStroke
      color={color}
      d="M58.3649 35.9762C58.3649 41.1494 54.022 45.3986 48.5951 45.3986C43.1682 45.3986 38.8252 41.1494 38.8252 35.9762C38.8252 30.8029 43.1682 26.5537 48.5951 26.5537C54.022 26.5537 58.3649 30.8029 58.3649 35.9762Z"
      fill="white"
      stroke="#7A7A7A"
      strokeWidth="3"
    />
    <IconPathStroke
      color={color}
      d="M79.9059 39.4503C79.9059 43.8514 76.1899 47.4831 71.5257 47.4831C66.8615 47.4831 63.1455 43.8514 63.1455 39.4503C63.1455 35.0491 66.8615 31.4175 71.5257 31.4175C76.1899 31.4175 79.9059 35.0491 79.9059 39.4503Z"
      fill="white"
      stroke="#7A7A7A"
      strokeWidth="3"
    />
    <mask id="path-3-inside-1" fill="white">
      <path
        d="M67.9423 67.4755C67.9448 65.0838 67.4732 62.7151 66.5545 60.5045C65.6358 58.294 64.2879 56.2849 62.5879 54.592C60.8879 52.8991 58.869 51.5555 56.6465 50.638C54.424 49.7205 52.0414 49.2471 49.6348 49.2446C47.2282 49.2422 44.8446 49.7109 42.6202 50.6239C40.3959 51.5369 38.3742 52.8764 36.6708 54.5658C34.9673 56.2553 33.6153 58.2616 32.6921 60.4703C31.7689 62.679 31.2924 65.0468 31.29 67.4385L33.4263 67.4406C33.4285 65.3277 33.8494 63.236 34.665 61.2848C35.4806 59.3335 36.675 57.5611 38.1799 56.0686C39.6847 54.5761 41.4707 53.3927 43.4358 52.5862C45.4009 51.7796 47.5065 51.3655 49.6326 51.3677C51.7587 51.3698 53.8635 51.7881 55.827 52.5987C57.7904 53.4092 59.5739 54.5962 61.0757 56.0917C62.5776 57.5872 63.7683 59.3621 64.5799 61.315C65.3915 63.2679 65.8082 65.3605 65.806 67.4733L67.9423 67.4755Z"
      />
    </mask>
    <IconPathStroke
      color={color}
      d="M67.9423 67.4755C67.9448 65.0838 67.4732 62.7151 66.5545 60.5045C65.6358 58.294 64.2879 56.2849 62.5879 54.592C60.8879 52.8991 58.869 51.5555 56.6465 50.638C54.424 49.7205 52.0414 49.2471 49.6348 49.2446C47.2282 49.2422 44.8446 49.7109 42.6202 50.6239C40.3959 51.5369 38.3742 52.8764 36.6708 54.5658C34.9673 56.2553 33.6153 58.2616 32.6921 60.4703C31.7689 62.679 31.2924 65.0468 31.29 67.4385L33.4263 67.4406C33.4285 65.3277 33.8494 63.236 34.665 61.2848C35.4806 59.3335 36.675 57.5611 38.1799 56.0686C39.6847 54.5761 41.4707 53.3927 43.4358 52.5862C45.4009 51.7796 47.5065 51.3655 49.6326 51.3677C51.7587 51.3698 53.8635 51.7881 55.827 52.5987C57.7904 53.4092 59.5739 54.5962 61.0757 56.0917C62.5776 57.5872 63.7683 59.3621 64.5799 61.315C65.3915 63.2679 65.8082 65.3605 65.806 67.4733L67.9423 67.4755Z"
      fill="#ABC539"
      stroke="#7A7A7A"
      strokeWidth="4"
      mask="url(#path-3-inside-1)"
    />
    <mask id="path-4-inside-2" fill="white">
      <path
        d="M88.4865 67.6635C88.4898 64.4766 87.5512 61.3588 85.7874 58.6972C84.0235 56.0356 81.512 53.9474 78.5646 52.6916C75.6171 51.4359 72.3635 51.068 69.2075 51.6335C66.0515 52.1991 63.1321 53.6732 60.8117 55.873L62.1821 57.3007C64.2212 55.3677 66.7866 54.0723 69.5598 53.5753C72.3331 53.0783 75.1922 53.4016 77.7823 54.5051C80.3723 55.6086 82.5793 57.4436 84.1293 59.7824C85.6792 62.1213 86.504 64.861 86.5011 67.6615L88.4865 67.6635Z"
      />
    </mask>
    <IconPathStroke
      color={color}
      d="M88.4865 67.6635C88.4898 64.4766 87.5512 61.3588 85.7874 58.6972C84.0235 56.0356 81.512 53.9474 78.5646 52.6916C75.6171 51.4359 72.3635 51.068 69.2075 51.6335C66.0515 52.1991 63.1321 53.6732 60.8117 55.873L62.1821 57.3007C64.2212 55.3677 66.7866 54.0723 69.5598 53.5753C72.3331 53.0783 75.1922 53.4016 77.7823 54.5051C80.3723 55.6086 82.5793 57.4436 84.1293 59.7824C85.6792 62.1213 86.504 64.861 86.5011 67.6615L88.4865 67.6635Z"
      fill="#ABC539"
      stroke="#7A7A7A"
      strokeWidth="4"
      mask="url(#path-4-inside-2)"
    />
    <IconPathStroke
      color={color}
      d="M33.2835 66.5481H4C2.34315 66.5481 1 65.2049 1 63.5481V11.1824C1 9.52552 2.34315 8.18237 4 8.18237H8.6605M44.7743 27.4541V11.1824C44.7743 9.52552 43.4311 8.18237 41.7743 8.18237H22.8871"
      stroke="#7A7A7A"
      strokeWidth="3"
    />
    <IconPathStroke
      color={color}
      d="M7.9502 42.9238H29.4899"
      stroke="#7A7A7A"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M7.9502 35.2803H29.4899"
      stroke="#7A7A7A"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M7.9502 50.5676H29.4899"
      stroke="#7A7A7A"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M7.9502 58.2102H23.2365"
      stroke="#7A7A7A"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M39.2139 66.5474L87.1571 66.5474"
      stroke="#7A7A7A"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M22 8C22 11.9278 19.03 15 15.5 15C11.97 15 9 11.9278 9 8C9 4.07223 11.97 1 15.5 1C19.03 1 22 4.07223 22 8Z"
      stroke="#7A7A7A"
      strokeWidth="3"
    />
    <IconPathStroke
      color={color}
      d="M10 12V23L15.5 18.7222L21 23V12"
      stroke="#7A7A7A"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconWrapper>
)

export { Solutions }
