import styled, { css } from "styled-components"
import { Link } from "gatsby"

const opacity = css`
  opacity: 0;
  transition: opacity 0.4s ease-in;
`

const flex = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const ImageContainer = styled.div`
  margin-top: 55px;
  z-index: 1;
  ${flex}
`

export const TitleContainer = styled.div`
  margin: 35px 0;
  z-index: 1;
  ${flex}
`

export const DescriptionContainer = styled.div`
  margin-bottom: 40px;
  flex-grow: 1;
  width: 100%;
  text-align: center;
  ${opacity}
  z-index: 1;
`
export const LinkContainer = styled.div`
  margin-bottom: 40px;
  ${opacity}
  z-index: 1;
`

export const CardWrapper = styled(Link)`
  padding: 0 35px;
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  align-items: flex-start;
  width: 100%;
  height: 500px;
  position: relative;
  text-decoration: none;
  &:hover {
    box-shadow: 0px 4.85258px 30px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    ${DescriptionContainer}, ${LinkContainer} {
      opacity: 1;
    }
  }
  &:hover::after {
    opacity: 1;
  }
  &:hover::before {
    opacity: 1;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s ease-in;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 8px;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s ease-in;
    background: ${({ theme }) => theme.color.green};
  }
`
