import styled from "styled-components"

export const ArrowWrapper = styled.div`
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 20px;
  }
`

export const SliderNav = styled.div`
  display: flex;
`

export const SliderWrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  .swiper-container {
    width: 100%;
    height: calc(100% - 150px);
  }
  .swiper-slide {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 861px) {
    .swiper-container {
      height: calc(100% - 80px);
    }
  }
`
export const TextWrapper = styled.div`
  max-width: 720px;
  h2 {
    color: #fff;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    h2 {
      font-size: 40px;
    }
  }
`
