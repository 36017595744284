import React from 'react';
import {
  PartnersSlider,
  PartnersWrapper,
  SlideImageBox,
  SlideImageWrapper,
} from './styles.js';
import {SectionTitle} from 'components/Typography';

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import {BrushedTextContainer} from 'components/BrushedTextContainer';
import Image from 'gatsby-image';
import {usePartnersImage} from '../../../shared/Hooks/Homepage/usePartnersImage';

const Partners = ({data}) => {
  const images = usePartnersImage();

  return (
    <PartnersWrapper>
      <SectionTitle>{data.title}</SectionTitle>
      <BrushedTextContainer posY={'-50px'} x={[
        '-10',
        '15',
      ]}>
        {data.parallaxText}
      </BrushedTextContainer>
      <PartnersSlider>
        <Swiper
          spaceBetween={40}
          speed={3000}
          loop={true}
          slidesPerView={6}
          autoplay={{delay: 0}}
          breakpoints={{
            992: {
              slidesPerView: 6,
            },
            600: {
              slidesPerView: 4,
            },
            430: {
              slidesPerView: 3,
            },
            320: {
              slidesPerView: 2,
            },
          }}
        >
          {Object.keys(images).map((key, i) => (
            <SwiperSlide>
              <SlideImageWrapper>
                <SlideImageBox>
                  <Image key={i} fluid={images[key].childImageSharp.fluid}/>
                </SlideImageBox>
              </SlideImageWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </PartnersSlider>
    </PartnersWrapper>
  );
};

export {Partners};
