import React from 'react';

import {
  LabelContent,
  LabelWrapper,
  TitleWrapper,
  ButtonsWrapper,
  Button,
} from './styles';
import {SectionTitle} from 'components/Typography';
import {withTheme} from 'styled-components';
import {AboutUsWrapper} from 'sections/Homepage/AboutUs/styles';

const Label = withTheme(({
  data,
  theme,
  handleClickIndustry,
  handleClickType,
}) => {
  return (
    <>
      <AboutUsWrapper>
        <div style={{transform: 'translateY(100px)'}}>
          <SectionTitle color={theme.color.white}>{data.title}</SectionTitle>
        </div>
      </AboutUsWrapper>
      <LabelWrapper>
        <TitleWrapper></TitleWrapper>
        <ButtonsWrapper>
          <Button onClick={handleClickType}>{data.type}</Button>
          <Button onClick={handleClickIndustry}>{data.industry}</Button>
        </ButtonsWrapper>
      </LabelWrapper>
    </>
  );
});

export {Label};
