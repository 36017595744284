import styled from "styled-components"

import { Link } from "gatsby"
import { MediumHeader, Paragraph } from "components/Typography"

export const Button = styled(Link)`
  border: 2px solid ${props => props.theme.color.green};
  padding: 12px 32px;
  background-color: ${props => props.theme.color.green};
  color: #fff;
  text-decoration: none;
  transition: 0.4s all ease;
  &:hover {
    background-color: #fff;
    color: ${props => props.theme.color.green};
  }
`

export const AboutUsContainer = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 120px auto;
  position: relative;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    margin: 60px 0;
  }
`
export const AboutUsImageWrapper = styled.div`
  max-width: 670px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 5.3vw;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    max-width: 500px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    right: 40px;
    top: 110px;
  }
  @media screen and (max-width: 1080px) {
    max-width: 400px;
  }
  @media screen and (max-width: 861px) {
    position: relative;

    right: 0;
    top: 0;
    margin-top: 80px;
    max-width: 100%;
  }
`
export const AboutUsWrapper = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    max-width: 860px;
  }
  @media screen and (max-width: 861px) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const AboutUsTextWrapper = styled.div`
  max-width: 515px;
  width: 100%;
  margin-top: 70px;
  padding-right: 20px;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    width: 450px;
  }
  @media screen and (max-width: 1080px) {
    width: 400px;
  }
  @media screen and (max-width: 861px) {
    max-width: 100%;
    width: 100%;
  }
`

export const Title = styled(MediumHeader)`
  max-width: 400px;
  position: relative;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    font-size: 45px !important;
    max-width: 100%;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    font-size: 30px !important;
    max-width: 250px;
  }
`

export const Text = styled(Paragraph)`
  margin: 30px 0 60px 0;
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    font-size: 14px;
  }
`
