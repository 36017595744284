import React from "react"

import { MainSliderContainer } from "./styles"

import { Slider } from "./Slider"
import { Video } from "./Video"

const MainSlider = ({data}) => {
  return (
    <MainSliderContainer>
      <Video />
      <Slider data={data} />
    </MainSliderContainer>
  )
}

export { MainSlider }
