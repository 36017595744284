import styled, { css } from "styled-components"
import { Swiper } from "swiper/react"
import {
  CardWrapper,
  DescriptionContainer,
  LinkContainer,
} from "sections/Homepage/Projects/Card/styles"

export const Container = styled.div`
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -50px;
    left: 0%;
    width: 100%;
    height: 200px;
    background-color: #f8f8f8;
  }
`

export const StyledSwiper = styled(Swiper)`
  position: relative;
  width: 90%;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    .swiper-slide-active {
      ${CardWrapper} {
        box-shadow: 0px 4.85258px 30px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        &::after,
        ::before,
        ${DescriptionContainer}, ${LinkContainer} {
          opacity: 1;
        }
      }
    }
  }
`

const arrowsPosition = css`
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    top: 115px;
    transform: translateY(-50%);
  }
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    top: 125px;
  }
`

export const ArrowLeftWrapper = styled.div`
  ${arrowsPosition};
  left: 30px;
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    left: 600px;
  }
  @media screen and (min-width: 900px) {
    left: 660px;
  }
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    left: 830px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.laptopBig}) {
    left: 1050px;
  }
  svg {
    path, circle {
      stroke: ${props=>props.theme.color.gray};
      transition: all 0.4s ease-in;
    }
    &:hover {
      path, circle {
        stroke: ${props=>props.theme.color.green};
      }
    }
  }
`

export const ArrowRightWrapper = styled.div`
  ${arrowsPosition};
  right: 30px;
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    left: 680px;
  }
  @media screen and (min-width: 900px) {
    left: 770px;
    right: unset;
  }
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    left: 940px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.laptopBig}) {
    left: 1160px;
  }
  svg {
    path, circle {
      stroke: ${props=>props.theme.color.gray};
      transition: all 0.4s ease-in;
    }
    &:hover {
      path, circle {
        stroke: ${props=>props.theme.color.green};
      }
    }
  }
`
