export const SLIDER_OPTIONS = {
	spaceBetween: 0,
	slidesPerView: 6,
	loop: true,
	autoplay: { delay: 5000 },
	navigation: { nextEl: ".sliderNext", prevEl: ".sliderPrev" },
	breakpoints: {
		100: {
			slidesPerView: 1,
			spaceBetween: 27,
		},
		601: {
			slidesPerView: 1,
			spaceBetween: 50,
		},
		769: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1025: {
			slidesPerView: 3,
			spaceBetween: 5,
		},
		1281: {
			slidesPerView: 4,
			spaceBetween: 5,
		},
		1441: {
			slidesPerView: 5,
			spaceBetween: 5,
		},
		1601: {
			slidesPerView: 5,
			spaceBetween: 5,
		},
	}
}
