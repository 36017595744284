import React from 'react';
import {ArrowWrapper, SliderNav, SliderWrapper, TextWrapper} from './styles';
import {ArrowRightCircle, ArrowLeftCircle} from 'components/Icons';

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import uuid from 'react-uuid';

const Slider = ({data}) => {
  return (
    <SliderWrapper>
      <Swiper
        slidesPerView={1}
        loop={true}
        effect={'fade'}
        fadeEffect={{crossFade: true}}
        autoplay={{delay: 4000}}
        speed={1000}
        navigation={{
          prevEl: '.prevSlide',
          nextEl: '.nextSlide',
        }}
      >
        {data.map((slide) => (
          <SwiperSlide key={uuid()}>
            <TextWrapper>{slide.text}</TextWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
      <SliderNav>
        <ArrowWrapper className="prevSlide">
          <ArrowLeftCircle width={'30px'} heigth={'30px'} color={'#fff'}/>
        </ArrowWrapper>
        <ArrowWrapper className="nextSlide">
          <ArrowRightCircle width={'30px'} heigth={'30px'} color={'#fff'}/>
        </ArrowWrapper>
      </SliderNav>
    </SliderWrapper>
  );
};

export {Slider};
