import styled from "styled-components"

export const CompanyAssetsContainer = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 120px auto;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    max-width: 860px;
  }
  @media screen and (max-width: 861px) {
    padding-right: 20px;
    padding-right: 20px;
  }
`
export const CompantyAssetsWrapper = styled.div`
  margin-top: 120px;
  a {
    text-decoration: none;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    padding: 0 20px;
  }
`
