import React from 'react';
import {SectionTitle} from 'components/Typography';

import {SmallHeader, Paragraph} from 'components/Typography';

import {ButtonLink} from 'components/Buttons/';

import {withTheme} from 'styled-components';

import {
  ArrowWrapper,
  CasestudyContainer,
  CasestudySliderWrapper,
  CasestudyWrapper,
  SlideImageBox,
  SlideTextBox,
  SlideTextWrapper,
  SlideImageWrapper,
  SlidesWrapper,
  TitleWrapper,
} from './styles';

import {ArrowRightCircle, ArrowLeftCircle} from 'components/Icons';
import {BrushedTextContainer} from 'components/BrushedTextContainer';
import {Swiper, SwiperSlide} from 'swiper/react';

const CasestudySlider = withTheme(({
  theme,
  data,
}) => {
  return (
    <CasestudyContainer>
      <BrushedTextContainer x={[
        -10,
        15,
      ]}>
        {data.parallaxText}
      </BrushedTextContainer>
      <CasestudyWrapper>
        <TitleWrapper>
          <SectionTitle>{data.title}</SectionTitle>
          {/* <PaginationWrapper>
            <div className="pagination"></div>
          </PaginationWrapper> */}
        </TitleWrapper>
        <CasestudySliderWrapper>
          <ArrowWrapper className="prev-slide">
            <ArrowLeftCircle width={'60px'} height={'60px'} color={'#d7d7d7'}/>
          </ArrowWrapper>
          <SlidesWrapper>
            <Swiper
              slidesPerView={1}
              loop={true}
              navigation={{
                nextEl: '.next-slide',
                prevEl: '.prev-slide',
              }}
              pagination={{
                el: '.pagination',
                type: 'fraction',
              }}
            >
              {data.data.map((slide, i) => (
                <SwiperSlide key={i}>
                  <SlideImageWrapper>
                    <SlideImageBox>{slide.image}</SlideImageBox>
                    <SlideTextBox>
                      <SlideTextWrapper>
                        <SmallHeader>{slide.title} </SmallHeader>
                        <Paragraph>{slide.shortText} </Paragraph>
                      </SlideTextWrapper>
                      <ButtonLink to={slide.href}>{data.btnText}</ButtonLink>
                    </SlideTextBox>
                  </SlideImageWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlidesWrapper>
          <ArrowWrapper className="next-slide">
            <ArrowRightCircle
              width={'60px'}
              height={'60px'}
              color={'#d7d7d7'}
            />
          </ArrowWrapper>
        </CasestudySliderWrapper>
      </CasestudyWrapper>
    </CasestudyContainer>
  );
});

export {CasestudySlider};
