import {useStaticQuery, graphql} from 'gatsby';

export const useBackgroundImage = () => {
  return useStaticQuery(graphql`
      query {
          aboutFirm: file(relativePath: { eq: "img/BrushedText/about-firm.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          aboutUs: file(relativePath: { eq: "img/BrushedText/about-us.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          advantages: file(relativePath: { eq: "img/BrushedText/advantages.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          case: file(relativePath: { eq: "img/BrushedText/case.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          caseStudy: file(relativePath: { eq: "img/BrushedText/case-study.png" }) {
              childImageSharp {
                  fixed(height: 250, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          clients: file(relativePath: { eq: "img/BrushedText/clients.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          contact: file(relativePath: { eq: "img/BrushedText/contact.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          process: file(relativePath: { eq: "img/BrushedText/process.png" }) {
              childImageSharp {
                  fixed(height: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          projects: file(relativePath: { eq: "img/BrushedText/projects.png" }) {
              childImageSharp {
                  fixed(height: 200, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          terms: file(relativePath: { eq: "img/BrushedText/terms.png" }) {
              childImageSharp {
                  fixed(height: 200, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
      }
  `);
};
